import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import * as Styles from "../styles/effect.module.css";

const TOS = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Terms of Service - KATSANA® Integrated Fleet Management & Industrial
          Operation Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.katsana.com/terms-of-service/"
        />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.katsana.com/terms-of-service/"
        />
        <meta
          property="og:title"
          content="Terms of Service - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0  md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            Important stuff that you need to know
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Terms of Service
          </h1>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-white relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,0)] to-[hsla(204,26%,99%,0)] via-white text-gray-800  relative">
          <h3 className="text-2xl font-medium">
            Welcome, and Thank you for using Katsana!
          </h3>
          <p className="mt-6 leading-7">
            Katsana’s products and services are provided by KATSANA Holdings Sdn
            Bhd. These terms of Service (“Terms”) govern your access to and use
            of Katsana’s websites, products, and services (“Products”). Please
            read these Terms carefully, and contact us if you have any
            questions. By accessing or using our Products, you are agree to be
            bound by these Terms and by our Privacy Policy.
          </p>
          <h3 className="mt-12 text-2xl font-medium">
            Using Katsana GPS Tracking Service
          </h3>
          <h4 className="mt-6 text-xl font-medium">Who can use Katsana</h4>
          <p className="mt-6 leading-7">
            You may use our Product only if you can form a binding contract with
            Katsana, and only in compliance with these Terms and all applicable
            laws. When you purchase your Katsana product, you must provide us
            with accurate and complete information
            <br />
            <br />
            If you open an account of behalf of a company, organisation or other
            entity, then (a) “you” includes you an that entity, and (b) you
            represent and warrant that you are authorised to grant all
            permissions and licenses provided in these Terms and bind the entity
            to these Terms, and that you agree to these Terms on the entity’s
            behalf.
            <br />
            <br />
            Some of our Products may be software that is downloaded to your
            computer, phone, tablet or other device. You agree that we may
            automatically upgrade those Products, and these Terms will apply to
            such upgrades.
          </p>
          <h4 className="mt-6 text-xl font-medium">Our license to you</h4>
          <p className="mt-6 leading-7">
            Subject to these Terms and our policies (including our Acceptable
            Usage Policy), we grant you a limited, non-exclusive,
            non-transferable, and revocable license to use our Products.
          </p>

          <h4 className="mt-6 text-xl font-medium">
            Commercial use of Katsana
          </h4>
          <p className="mt-6 leading-7">
            If you want to use our Products for commercial purposes, you must
            create business account and agree to our Business Terms of Service.
          </p>

          <h3 className="mt-12 text-2xl font-medium">Security </h3>
          <p className="mt-6 leading-7">
            We care about the security of our users. While we work with utmost
            diligence to protect the security of your information and account,
            Katsana GPS Tracker service cannot guarantee that unauthorised third
            parties will not be able to defeat our security measures. Please
            notify us immediately of any compromise or unauthorised use of your
            account.
          </p>

          <h3 className="mt-12 text-2xl font-medium">
            Third party Links, Sites and Services
          </h3>
          <p className="mt-6 leading-7">
            Our Products may contain links to third-party websites, advertisers,
            services, special offers, or other events or activities that are not
            owned or controlled by Katsana. We do not endorse or assume any
            responsibility for any such third-party sites, information,
            materials, products, or services. If you access any third party
            website, service, or content from Katsana, you do so at your own
            risk and you agree that Katsana will have no liability arising from
            your use of or access to any third-party website, service, or
            content.
          </p>

          <h3 className="mt-12 text-2xl font-medium">Termination</h3>
          <p className="mt-6 leading-7">
            Katsana may terminate or suspend this license at any time, with or
            without cause or notice to you. Your subscription payment will be
            pro-rated and returned to you upon such termination.
          </p>

          <h4 className="mt-6 text-xl font-medium">In summary</h4>
          <p className="mt-2 leading-7">
            We reserve the right to refuse service to anyone.
          </p>

          <h3 className="mt-12 text-2xl font-medium">Indemnity</h3>
          <p className="mt-6 leading-7">
            If you use our Products for illegal reasons prohibited by the Law
            and/or in violation of our service, as determined in our sole and
            absolute discretion, you agree to indemnify and hold harmless
            Katsana and its officers, directors, employees and agents, from and
            against any claims, suits, proceedings, disputes, demands,
            liabilities, damages, losses, costs and expenses, including, without
            limitation, reasonable legal and accounting fees (including costs of
            defence of claims, suits or proceedings brought by third parties),
            in any way related to (a) your access to use of our Products, (b)
            your location information, or (c) your breach of any of these Terms.
          </p>

          <h4 className="mt-6 text-xl font-medium">In summary</h4>
          <p className="mt-2 leading-7">
            If we are sued because of something you do on Katsana that goes
            against the Law and our Terms, you have to pay our costs.
          </p>

          <h3 className="mt-12 text-2xl font-medium">Disclaimers</h3>
          <p className="mt-6 leading-7">
            The Product and all included content and services are provided on an
            “as is” basis without warranty of any kind, whether expressed or
            implied.
            <br />
            <br />
            KATSANA SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
            DEALING OR USAGE OF TRADE.
          </p>

          <h3 className="mt-12 text-2xl font-medium">
            Limitation of Liability
          </h3>
          <p className="mt-6 leading-7">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, KATSANA SHALL NOT BE LIABLE
            FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
            DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED
            DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR
            OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR ACCESS TO OR USE OF
            OR INABILITY TO ACCESS OR USE THE PRODUCTS; (B) MISUSE OF OUR
            PRODUCTS AS DEFINED IN OUR TERMS AND THE LAW AS DETERMINED IN OUR
            SOLE AND ABSOLUTE DISCRETION; OR (C) UNAUTHORIZED ACCESS, USE OR
            ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL
            KATSANA’S AGGREGATE LIABILITY EXCEED ONE HUNDRED MALAYSIAN RINGGIT
            (RM100.00).
          </p>

          <h4 className="mt-6 text-xl font-medium">In summary</h4>
          <p className="mt-2 leading-7">
            We are building the best service we can for you but we can’t promise
            it will be perfect. We’re not liable for various things caused by
            misuse of our Products. If you think we are, let’s try to work it
            out like adults.
          </p>

          <h3 className="mt-12 text-2xl font-medium">
            Governing Law and Jurisdiction
          </h3>
          <p className="mt-6 leading-7">
            These Terms shall be governed by the laws of Malaysia, without
            respect to its conflict of law principles. Our Products are
            controlled and operated from Malaysia, and we make no
            representations that they are appropriate and or available for use
            in other locations.
          </p>

          <h3 className="mt-12 text-2xl font-medium">General Terms</h3>
          <p className="mt-6 leading-7">
            Notification Procedures and changes to these Terms.
            <br />
            Katsana reserves the right to determine the form and means of
            providing notifications to you, and you agree to receive legal
            notices electronically if we so choose. We may revise these Terms
            from time to time and the most current version will always be posted
            on our website. If a revision, in our sole discretion, is material
            we will notify you. By continuing to access or use the Products
            after revisions become effective, you agree to be bound by the
            revised Terms. If you do not agree to the new terms, please stop
            using the Products.
          </p>

          <h4 className="mt-6 text-xl font-medium">In summary</h4>
          <p className="mt-2 leading-7">
            If we’re making a big change to the terms, we’ll let you know.
          </p>

          <h4 className="mt-12 text-xl font-medium">Assignment.</h4>
          <p className="mt-2 leading-7">
            These Terms, and any rights and licenses granted hereunder, may not
            be transferred or assigned by you, but may be assigned by Katsana
            without restriction. Any attempted transfer or assignment in
            violation hereof shall be null and void.
          </p>

          <h4 className="mt-12 text-xl font-medium">
            Entire Agreement/Severability.
          </h4>
          <p className="mt-2 leading-7">
            These Terms, together with the Privacy Policy and any amendments and
            any additional agreements you may enter into with Katsana in
            connection with the Products, shall constitute the entire agreement
            between you and Katsana concerning the Products. If any provision of
            these Terms is deemed invalid, then that provision will be limited
            or eliminated to the minimum extent necessary, and the remaining
            provisions of these Terms will remain in full force and effect.
          </p>

          <h4 className="mt-12 text-xl font-medium">No Waiver.</h4>
          <p className="mt-2 leading-7">
            No waiver of any term of these Terms shall be deemed a further or
            continuing waiver of such term or any other term, and Katsana’s
            failure to assert any right or provision under these Terms shall not
            constitute a waiver of such right or provision.
          </p>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default TOS;
